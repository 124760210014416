import React from "react";
import styled from 'styled-components'
import {graphql} from 'gatsby';
import {ListViewItem, BackButton} from '@components/common';
import MainHeading from '@typo/MainHeading'
import Seo from "@components/Seo";
import { useTranslation } from 'react-i18next'
import Header from "@menus/header"

const CategoryWrapper = styled.div`
  padding: 5% 5%;
  background-color: ${props => props.theme.backgroundColor};
`


const StoryScenarioTemplate = (props) => {
  const { t } = useTranslation(['translations','pages'])
  const lang = props.pageContext.lang
  const customQuery = filterStories(props.data.allStory.edges)
  
  function filterStories(stories){
    //some FantasyId in context must be found in storys fantasy array of objects
    return stories.filter(item =>  item.node.scenario[lang].scenario === props.pageContext.scenarioName && item.node[lang].published);
  }

  return(
    <>
      <Header location={props.location}/> 
      <CategoryWrapper>
        <Seo description={t('pages:root.seoDescription')} title={`Scenario: ${props.pageContext.scenarioName}`}/>
        <BackButton/>
        <MainHeading>Scenario: {props.pageContext.scenarioName}</MainHeading>
        {customQuery && customQuery.map(edge =>(
          <ListViewItem
            id={edge.node.id}
            slug={edge.node[lang].slug}
            title={edge.node[lang].title}
            sampleAudioRef={edge.node[lang].sampleAudioRef}
            storyCover={edge.node.localImage.coverImage.gatsbyImageData}
            thumbnail={edge.node.localImage.smallImage.gatsbyImageData}
            duration={edge.node[lang].duration}
            voices={edge.node[lang].voices}
            mainFantasy={edge.node.mainFantasy[lang].fantasy}
            spiciness={edge.node.spiciness}
            premiumContent={edge.node[lang].premiumContent}
            audiodesiresOriginal={edge.node.audiodesiresOriginal}
            key={edge.node.id}
          />
        ))} 
      </CategoryWrapper>
    </>
  )
};

export const query = graphql`
query scenarioQuery(
  $isEn: Boolean!, 
  $isEs: Boolean!, 
  $isDe: Boolean!)
  {
  allStory {
    edges {
      node {
        id
        spiciness
        audiodesiresOriginal
        en @include(if: $isEn){
          duration
          featured
          freeAudioRef
          premiumAudioRef
          sampleAudioRef
          premiumContent
          published
          seoDescription
          seoTitle
          seriesStories
          slug
          summary
          title
          voices{
            name
            slug
          }
        }
        es @include(if: $isEs){
          duration
          featured
          freeAudioRef
          premiumAudioRef
          sampleAudioRef
          premiumContent
          published
          seoDescription
          seoTitle
          seriesStories
          slug
          summary
          title
          voices{
            name
            slug
          }
        }
        de @include(if: $isDe){
          duration
          featured
          freeAudioRef
          premiumAudioRef
          sampleAudioRef
          premiumContent
          published
          seoDescription
          seoTitle
          seriesStories
          slug
          summary
          title
          voices{
            name
            slug
          }
        }
        localImage {
          smallImage: childImageSharp {
            gatsbyImageData( placeholder: BLURRED,width: 100, height: 100, layout: CONSTRAINED)
          }
        }
        localImage {
          coverImage: childImageSharp {
            gatsbyImageData( placeholder: BLURRED,width: 600, height: 600, layout: CONSTRAINED)
          }
        }
        mainFantasy {
          en {
            fantasy
          }
          es {
            fantasy
          }
          de {
            fantasy
          }
        }
        fantasies {
          id
          en {
            fantasy
            slug
          }
          es {
            fantasy
            slug
          }
          de {
            fantasy
            slug
          }
        }
        genre {
          id
          en{
            genre
          }
          es{
            genre
          }
          de{
            genre
          }
          id
        }
        scenario {
          en {
            scenario
            slug
          }
          es {
            scenario
            slug
          }
          de {
            scenario
            slug
          }
        }
      }
    }
  }
  allFantasy {
    edges {
      node {
        en {
          fantasy
        }
        es {
          fantasy
        }
        de {
          fantasy
        }
      }
    }
  }
}
` 



export default StoryScenarioTemplate;